import React, {useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import { Fragment } from "react";
import Upload from "./components/upload";
import Login from "./components/login";
import LoginLayoutRoute from "./components/layout/LoginLayout";    
import DashboardLayoutRoute from "./components/layout/DashboardLayout";  
import Paymentgetway from "./components/paymentgateway";
import Exportpayment from "./components/Exportpayment";

const App = (props) => {
  const [currentPlan, setCurrentPlan] = useState("");

  return (
      <Router>
        <Switch>
          <Route exact path="/"><Redirect to="/upload" /></Route>
          {/* <DashboardLayoutRoute path="*" exact={true} component={Upload} /> */}
          <DashboardLayoutRoute path="/upload" exact={true} component={Upload} />
          <DashboardLayoutRoute path="/paymentgateway" exact={true} component={Paymentgetway} />
          <DashboardLayoutRoute path="/Exportpayment" exact={true} component={Exportpayment} />
        </Switch>
      </Router>
  );
};

export default App;
