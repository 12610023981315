import {Card, Stack, ButtonGroup, Button,Page, PageActions,TextField,Popover,DatePicker,Icon,OptionList,Select,Spinner,Banner} from '@shopify/polaris';
import React from 'react';
import {useState, useCallback, useEffect} from 'react';
import "./css/custom.css";
import { CalendarMajor} from '@shopify/polaris-icons';
import config from "../config.json";
import axios from 'axios';

const Exportpayment=(props)=> {

  const [holdPage, setHoldPage] = useState(false);
  const [orders, setorders] = useState([]);

  const [isFinancialStatusShow, setisFinancialStatusShow] = useState(false);
  const [financialStatusArr, setfinancialStatusArr] = useState([]);
  const handleSelectChange = (value) => { //payment gateway dropdown change function
    if(value.toLowerCase().includes('cod')){

      let tempfinancialStatusArr = [];
      let tempArr = orders.filter( (item) => item[0].toLowerCase().includes('cod') );
      for (let i = 0; i < tempArr.length; i++) {
        if(tempArr[i][0] == value){
          tempfinancialStatusArr = tempArr[i][1].Status;
        }
      }
      let distinct = (value, index, self) => { return self.indexOf(value) === index; }; 
      let distinctProductIds = tempfinancialStatusArr.filter(distinct);
      setfinancialStatusArr(distinctProductIds);
      setfinancialStatus(distinctProductIds[0]);
      setisFinancialStatusShow(true);
    }
    else{
      setfinancialStatus('');
      setisFinancialStatusShow(false);
    }
    setDropdown(value);
  };

  const [financialStatus, setfinancialStatus] = useState('');
  const handle_FS_SelectChange = (value) => { //payment gateway dropdown change function
    setfinancialStatus(value);
  };

  const [StartDateValue,setStartDateValue] = useState('Today')
  const [EndDateValue,setEndDateValue] = useState('Today')
  const generateCurrentMonth = (currentDate) => {
  return currentDate.getMonth();
  } 
  const generateCurrentYear = (currentDate) => {
  return currentDate.getFullYear();
  } 
  const [{month, year}, setDate] = useState({month: generateCurrentMonth(new Date), year: generateCurrentYear(new Date)});
  
  // const [{month, year}, setDate] = useState({month: 9, year: 2022});
  const [selectedDates, setSelectedDates] = useState({ start: new Date(), end: new Date() });

  const handleDateChange = (_date) => { //calender date change function
      var tempDate = new Date(_date.start); 
      var tempEndDate = new Date(_date.end); 
      setselectedDateToSend(tempDate);
      setStartDateValue(tempDate.getDate() + " " + tempDate.toLocaleString('en-US', {month: 'short'}) + " " + tempDate.getFullYear());
      setEndDateValue(tempEndDate.getDate() + " " + tempEndDate.toLocaleString('en-US', {month: 'short'}) + " " + tempEndDate.getFullYear());
      setSelectedDates({start: _date.start, end: _date.end });
      if (_date.start != _date.end){
        setPopoverActive(false);
        setselectedEndDateToSend(tempEndDate);
      }
      else{
        setselectedEndDateToSend('');
      }
      getordersbypayment(tempDate, 'end');
    };   

  const handleMonthChange = useCallback(
    (month, year) => setDate({month, year}),
    [],
  );
  const [popoverActive, setPopoverActive] = useState(false); //date
  const [popoverActive1, setPopoverActive1] = useState(false); // option
  const [dropdown, setDropdown] = useState([]);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  const downloadCSV = async (url) => {
    setHoldPage(true);
    try {
      // https://stackoverflow.com/a/53230807
      let response = await axios.get(url, {responseType: 'blob'});

      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);
  
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'file.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();
  
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      alert("Error, Try reducing the date range selected")
    }
    finally{
      setHoldPage(false);
    }
  }

  const getordersbypayment = async (date, datetype) => {
    setHoldPage(true);
    // console.log(date);
    setselectedDateToSend(date);
    if(datetype == 'end'){ datetype ='particularDate' }else{ datetype = 'startDate' }
    let getOrders = await axios.get(config.APIURL + `/payment/getwaystotal?shop=${global._globalShopUrl}&${datetype}=${date}`);
    // console.log("getOrders: ", getOrders);
    let arr = getOrders.data;
    let seperateOrders = {};
    // console.log(arr);
    let obj = {};
    let total = 0;
    for (let i = 0; i < arr.length; i++) {
      if(arr[i].node.transactions.length >  0){
        for (let k = 0; k < arr[i].node.transactions.length; k++) {
          let order_Transations = arr[i].node.transactions[k];
          let order_financial_status = arr[i].node.displayFinancialStatus;
          let trans_Ammount = order_Transations.amountSet.presentmentMoney.amount;
          if(order_Transations.status == "SUCCESS"){
            obj[order_Transations.gateway] == undefined ? obj[order_Transations.gateway] = {} : obj[order_Transations.gateway] = obj[order_Transations.gateway];
            obj[order_Transations.gateway].Total == undefined ? obj[order_Transations.gateway].Total = 0 : obj[order_Transations.gateway].Total = obj[order_Transations.gateway].Total;
            obj[order_Transations.gateway].OrderCount == undefined ? obj[order_Transations.gateway].OrderCount = 0 : obj[order_Transations.gateway].OrderCount = obj[order_Transations.gateway].OrderCount;
            obj[order_Transations.gateway].Status == undefined ? obj[order_Transations.gateway].Status = [] : obj[order_Transations.gateway].Status = obj[order_Transations.gateway].Status;
            obj[order_Transations.gateway].Total += Number(trans_Ammount);
            obj[order_Transations.gateway].Status.push(order_financial_status);
            obj[order_Transations.gateway].OrderCount++;
          }
          else if(order_Transations.gateway.toLowerCase().includes('cod') && order_Transations.status != "SUCCESS"){
            obj[order_Transations.gateway] == undefined ? obj[order_Transations.gateway] = {} : obj[order_Transations.gateway] = obj[order_Transations.gateway];
            obj[order_Transations.gateway].Total == undefined ? obj[order_Transations.gateway].Total = 0 : obj[order_Transations.gateway].Total = obj[order_Transations.gateway].Total;
            obj[order_Transations.gateway].OrderCount == undefined ? obj[order_Transations.gateway].OrderCount = 0 : obj[order_Transations.gateway].OrderCount = obj[order_Transations.gateway].OrderCount;
            obj[order_Transations.gateway].Status == undefined ? obj[order_Transations.gateway].Status = [] : obj[order_Transations.gateway].Status = obj[order_Transations.gateway].Status;
            obj[order_Transations.gateway].Total += Number(trans_Ammount);
            obj[order_Transations.gateway].Status.push(order_financial_status);
            obj[order_Transations.gateway].OrderCount++;
          }
          let a = seperateOrders[arr[i].node.paymentGatewayNames[k]] != null ? seperateOrders[arr[i].node.paymentGatewayNames[k]].ordersarray : [];
          total += order_Transations.amountSet.presentmentMoney.amount != null ? Number(order_Transations.amountSet.presentmentMoney.amount): 0;
          a.push(arr[i]);
          seperateOrders[arr[i].node.paymentGatewayNames[k]] = {ordersarray:a, "total": total};
          }
      }
    }
    let seperateOrdersarr = Object.keys(obj).map((key) => [key, obj[key]]);
    if(seperateOrdersarr.length > 0){
      setDropdown(seperateOrdersarr[0][0]);
      if(seperateOrdersarr[0][0].toLowerCase().includes('cod')){
        let tempfinancialStatusArr = [];
        let tempArr = seperateOrdersarr.filter( (item) => item[0].toLowerCase().includes('cod') );
        for (let i = 0; i < tempArr.length; i++) {
          if(tempArr[i][0] == seperateOrdersarr[0][0]){
            tempfinancialStatusArr = tempArr[i][1].Status;
          }
        }
        let distinct = (value, index, self) => { return self.indexOf(value) === index; }; 
        let distinctProductIds = tempfinancialStatusArr.filter(distinct);
        setfinancialStatusArr(distinctProductIds);
        setfinancialStatus(distinctProductIds[0]);
        setisFinancialStatusShow(true);
      }
      else{
        setisFinancialStatusShow(false);
      }
    }
    setorders(seperateOrdersarr);
    setHoldPage(false);
  }

  useEffect( () => {
    setStartDateValue("Today");
    setEndDateValue("Today");
    getordersbypayment(new Date(), 'start');
  }, [props]);
  

  const activator = ( 
    <div className="">
      <div class="Polaris-Labelled__LabelWrapper">
      <div class="Polaris-Label">
        <label id="PolarisSelect5Label" for="PolarisSelect5" class="Polaris-Label__Text">
          Select Date Range
        </label>
      </div>
      </div>
      <Button label = "Select Date Range" textAlign="left" onClick={togglePopoverActive} > 
        <div className=''>
        <Stack spacing="extraLoose"  >
          <Stack.Item >
            {
              StartDateValue == EndDateValue ?
                <div className='button__date__text'>{StartDateValue} </div>:
                <div className='button__date__text'>{StartDateValue} - {EndDateValue} </div>
            }
          </Stack.Item>
          <Icon source={CalendarMajor} color="base"  />
        </Stack> 
        </div>
      </Button>
    </div>
  );

  const [selectedDateToSend, setselectedDateToSend] = useState(); //post this date to api
  const [selectedEndDateToSend, setselectedEndDateToSend] = useState(); //post this end date to api

  const handleDateRangeButtons = async (button) => {
    setPopoverActive(false);
    let startDate = '';

    switch (button){
      case 1: startDate = new Date();
          // console.log(startDate);
          setStartDateValue("Today");
          setEndDateValue("Today");
          break; //'today'; 
      case 2: startDate = new Date();
      
          let yesterday = new Date(startDate)
          yesterday.setDate(yesterday.getDate() - 1)
          startDate.toDateString()
          yesterday.toDateString()
          // console.log(yesterday)
          startDate = yesterday;
          setStartDateValue("Yesterday");
          setEndDateValue("Yesterday");
          break; //'yesterday'; 
      case 3: startDate = new Date();

          let weekthis = new Date(startDate)
          weekthis.setDate(weekthis.getDate() - 7)
          startDate.toDateString()
          weekthis.toDateString()
          // console.log(weekthis)
          setStartDateValue("This week");
          setEndDateValue("This week");
          startDate = weekthis;
          break; //'this week'
      case 4: startDate = new Date();
          let monththis = new Date(startDate)
          monththis.setDate(monththis.getDate() - 31)
          startDate.toDateString()
          monththis.toDateString()
          // console.log(monththis)
          setStartDateValue("This month");
          setEndDateValue("This month");
          startDate = monththis;
          //   setDateValue(monththis);
          break; //'this month'
      default: alert('Something went wrong, please try again later.'); break;
    }
    await getordersbypayment(startDate, 'start');
    setselectedDateToSend(startDate);
    setselectedEndDateToSend('');
    setPopoverActive(false);
  }

  return (
    <div className={holdPage ? "list-page-overlay" : "list-page-overlay-visible"}>
    <Page title='Export Payment Details' fullWidth>
      {orders.length > 0 ?
      null
      :
        <Banner title="No orders found for the selected date range." status=""></Banner>
      }
      <PageActions />
        <Card>
          <Card.Section>
          <Stack alignment='leading' distribution='leading'>
            <Stack.Item>
              <Popover
                active={popoverActive}
                activator={activator}
                autofocusTarget="first-node"
                onClose={togglePopoverActive}
                >
                <Card>
                  <Card.Section>
                    <Stack>
                      <Stack.Item>
                        <Stack vertical>
                          <Stack.Item><p onClick={() => handleDateRangeButtons(1)} className='date_range_buttons'>Today</p></Stack.Item>
                          <Stack.Item><p onClick={() => handleDateRangeButtons(2)} className='date_range_buttons'>Yesterday</p></Stack.Item>
                          <Stack.Item><p onClick={() => handleDateRangeButtons(3)} className='date_range_buttons'>This week</p></Stack.Item>
                          <Stack.Item><p onClick={() => handleDateRangeButtons(4)} className='date_range_buttons'>This month</p></Stack.Item>
                        </Stack>
                      </Stack.Item>
                      <Stack.Item>
                          <DatePicker
                          month={month}
                          year={year}
                          onChange={handleDateChange}
                          onMonthChange={handleMonthChange}
                          selected={selectedDates}
                          allowRange
                          />
                      </Stack.Item>
                    </Stack>
                  </Card.Section>
                </Card>
              </Popover>
            </Stack.Item>
            <Stack.Item>
              <div className='select__gateway__text'>
              <Select
                label="Select payment gateway"
                options={
                  orders.map((item)  => (
                      {"label": item[0] == '' ? "No Gateway" : item[0], "value": item[0]}
                    ))
                }
                onChange={handleSelectChange}
                value={dropdown}
              /></div>
            </Stack.Item>

            {isFinancialStatusShow ?
              <Stack.Item>
              <div className='select__gateway__text'>
              <Select
                label="Select financial status"
                options={
                  financialStatusArr.map((item)  => (
                      {"label": item, "value": item}
                    ))
                }
                onChange={handle_FS_SelectChange}
                value={financialStatus}
              /></div>
            </Stack.Item>
            : null }

            {holdPage ? <Stack.Item><div className='spinner__div'><Spinner ></Spinner></div></Stack.Item>: null}
          </Stack>
          </Card.Section>
          <Card.Section>
            <Stack alignment='center'>
               {/* export button */}
                {orders.length > 0 ?
                <span> 
                {
                  financialStatus == '' ? 
                  <Button primary onClick={async () => await downloadCSV(`${ config.APIURL}/export/csvorders?shop=${global._globalShopUrl}&startDate=${selectedDateToSend}${selectedEndDateToSend && selectedEndDateToSend != '' ? '&endDate=' + selectedEndDateToSend : ''}&gateway=${dropdown}`) }>Export Now</Button>
                  : 
                  <Button primary onClick={async () => await downloadCSV(`${ config.APIURL}/export/csvorders?shop=${global._globalShopUrl}&startDate=${selectedDateToSend}${selectedEndDateToSend && selectedEndDateToSend != '' ? '&endDate=' + selectedEndDateToSend : ''}&gateway=${dropdown}&financial_status=${financialStatus}`) }>Export Now</Button>
                }
                </span>: 
                <Button primary disabled>Export Now</Button>
                }              
            </Stack>
          </Card.Section>
      </Card>
    </Page>
    </div>
  );
}
export default Exportpayment;