import {Card, List,Page, Stack, Button, Popover, DatePicker, Spinner, Heading, PageActions, Banner} from '@shopify/polaris';
// import { Popover } from 'bootstrap';
import React, {useEffect, useState, useCallback} from 'react';
import axios from 'axios';
import config from "../config.json";
import "./css/productSearchHTML.css";
import CurrencyFormat from 'react-currency-format';

const Paymentgetway = (props) => {

  const [holdPage, setHoldPage] = useState(false);
  const generateCurrentMonth = (currentDate) => {
  return currentDate.getMonth();
  } 
  const generateCurrentYear = (currentDate) => {
  return currentDate.getFullYear();
  } 
  const [{month, year}, setDate] = useState({month: generateCurrentMonth(new Date), year: generateCurrentYear(new Date)});
  
  // const [{month, year}, setDate] = useState({month: 9, year: 2022});
  const [selectedDates, setSelectedDates] = useState({ start: new Date(), end: new Date() });

  const handleDateChange = (_date) => { //calender date change function
      var tempDate = new Date(_date.start); 
      setselectedDateToSend(tempDate);
      console.log(tempDate);
      setDateValue(tempDate.getDate() + " " + tempDate.toLocaleString('en-US', {month: 'short'}) + " " + tempDate.getFullYear());
      setSelectedDates({start: _date.start, end: _date.end });
      setPopoverActive(false);
      getordersbypayment(tempDate, 'end');
    };   

  const handleMonthChange = useCallback(
    (month, year) => setDate({month, year}),
    [],
  );
  const [DateValue,setDateValue] = useState('Today')
  const [popoverActive, setPopoverActive] = useState(false); //dropdown popover

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
    {DateValue}
    </Button>
  );

  const [orders, setorders] = useState([]);
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [gatewayTotals, setgatewayTotals] = useState({});
  const getordersbypayment = async (date, datetype) => {
    setHoldPage(true);
    console.log(date);
    if(datetype == 'end'){ datetype ='particularDate' }else{ datetype = 'startDate' }

    try{
      let url = config.APIURL + "/shopdetails/getShopDetails?shop=" + global._globalShopUrl;
      let CurrencySymbolAPI = await axios(url);
      if (CurrencySymbolAPI.data.status == "success") {
        let tempCurrencySymbol = '';
        if(CurrencySymbolAPI.data.shopDetails){
          tempCurrencySymbol = CurrencySymbolAPI.data.shopDetails.money_format;
          tempCurrencySymbol = tempCurrencySymbol.substring( 0, tempCurrencySymbol.indexOf("{") );
        }        
        setCurrencySymbol(tempCurrencySymbol);
      } 
    }
    catch(e){}

    let getOrders = await axios.get(config.APIURL + `/payment/getwaystotal?shop=${global._globalShopUrl}&${datetype}=${date}`);
    let arr = getOrders.data;
    let seperateOrders = {};
    console.log("seperateOrders: ", seperateOrders);
    console.log("arr",arr);
    let obj ={};
    let total = 0;
    let output = [], l = arr.length, o;
    for (let i = 0; i < arr.length; i++) {
      //+++++++++++++++++ new changes
      // if(arr[i].node.transactions.length >  0){
      //   for (let k = 0; k < arr[i].node.transactions.length; k++) {
      //     let order_Transations = arr[i].node.transactions[k];
      //     let trans_Ammount = order_Transations.amountSet.presentmentMoney.amount;
      //     // let trans_unsettled_Ammount = order_Transations.totalUnsettledSet.presentmentMoney.amount;
      //     if(order_Transations.status == "SUCCESS"){
      //       obj[order_Transations.gateway] == undefined ? obj[order_Transations.gateway] = {} : obj[order_Transations.gateway] = obj[order_Transations.gateway];
      //       obj[order_Transations.gateway].Total == undefined ? obj[order_Transations.gateway].Total = 0 : obj[order_Transations.gateway].Total = obj[order_Transations.gateway].Total;
      //       obj[order_Transations.gateway].OrderCount == undefined ? obj[order_Transations.gateway].OrderCount = 0 : obj[order_Transations.gateway].OrderCount = obj[order_Transations.gateway].OrderCount;
      //       obj[order_Transations.gateway].Total += Number(trans_Ammount);
      //       obj[order_Transations.gateway].OrderCount++; /* += {Total: trans_Ammount, OrderCount: 1}  */
            
      //       // let a = seperateOrders[arr[i].node.paymentGatewayNames[k]] != null ? seperateOrders[arr[i].node.paymentGatewayNames[k]].ordersarray : [];
      //       // total += arr[i].node.totalPriceSet.presentmentMoney.amount != null ? Number(arr[i].node.totalPriceSet.presentmentMoney.amount): 0;
      //       // a.push(arr[i]);
      //       // seperateOrders[arr[i].node.paymentGatewayNames[k]] = {ordersarray:a, "total": total};
        
      //     }
      //     }
      // }
      if(arr[i].node.transactions.length >  0){
        let OrderNames = [];
        for (let k = 0; k < arr[i].node.transactions.length; k++) {
          let order_Transations = arr[i].node.transactions[k];
          let trans_Ammount = order_Transations.amountSet.presentmentMoney.amount;
          if(order_Transations.status == "SUCCESS" && order_Transations.kind != "VOID" ){
            obj[order_Transations.gateway] == undefined ? obj[order_Transations.gateway] = {} : obj[order_Transations.gateway] = obj[order_Transations.gateway];
            obj[order_Transations.gateway].Total == undefined ? obj[order_Transations.gateway].Total = 0 : obj[order_Transations.gateway].Total = obj[order_Transations.gateway].Total;
            obj[order_Transations.gateway].OrderCount == undefined ? obj[order_Transations.gateway].OrderCount = 0 : obj[order_Transations.gateway].OrderCount = obj[order_Transations.gateway].OrderCount;
            obj[order_Transations.gateway].AllOrders == undefined ? obj[order_Transations.gateway].AllOrders = [] : obj[order_Transations.gateway].AllOrders = obj[order_Transations.gateway].AllOrders;
            obj[order_Transations.gateway].Total += Number(trans_Ammount);
            
            let __name = arr[i].node.name, flag = false;
            
            if(!output.includes(__name)){
              flag = true;
            output.push(__name);
            }

            if(flag){
              obj[order_Transations.gateway].OrderCount++;
              obj[order_Transations.gateway].AllOrders.push(arr[i].node);
            }
            
            // for( o=0; o<l; o++) {
                
            // }
            // obj[order_Transations.gateway].AllOrders.push(arr[i].node);
            // if( !OrderNames.includes(arr[i].node.name) ){
            //   obj[order_Transations.gateway].OrderCount++; / += {Total: trans_Ammount, OrderCount: 1}  /
            // }
            // obj[order_Transations.gateway].OrderCount = 1;
            
            // let a = seperateOrders[arr[i].node.paymentGatewayNames[k]] != null ? seperateOrders[arr[i].node.paymentGatewayNames[k]].ordersarray : [];
            // total += arr[i].node.totalPriceSet.presentmentMoney.amount != null ? Number(arr[i].node.totalPriceSet.presentmentMoney.amount): 0;
            // a.push(arr[i]);
            // seperateOrders[arr[i].node.paymentGatewayNames[k]] = {ordersarray:a, "total": total};
            
          }
        }
      }
      
      // let a = seperateOrders[arr[i].node.paymentGatewayNames[]] != null ? seperateOrders[arr[i].node.paymentGatewayNames[0]].ordersarray : [];
      // total += arr[i].node.totalPriceSet.presentmentMoney.amount != null ? Number(arr[i].node.totalPriceSet.presentmentMoney.amount): 0;
  
    }
    console.log("obj: ", obj);
    console.log("output: ", output);

    setgatewayTotals(obj);
    console.log("obj: ", obj);
    let seperateOrdersarr = Object.keys(obj).map((key) => [key, obj[key]]);
    console.log("seperateOrdersarr: ", seperateOrdersarr);
    for (let i = 0; i < seperateOrdersarr.length; i++) {
      seperateOrdersarr[i][1].total = obj[seperateOrdersarr[i][0]];
      
    }
    console.log('----------');
    console.log(seperateOrders);
    setorders(seperateOrdersarr);
    setHoldPage(false);
  }

  useEffect( () => {
    getordersbypayment(new Date(), 'start');
  }, [props]);

  const [selectedDateToSend, setselectedDateToSend] = useState(new Date()); //post this date to api

  const handleDateRangeButtons = async (button) => {
    setPopoverActive(false);
    let startDate = '';

    switch (button){
      case 1: startDate = new Date();
          console.log(startDate);
          setDateValue("Today");
          break; //'today'; 
      case 2: startDate = new Date();
      
          let yesterday = new Date(startDate)
          yesterday.setDate(yesterday.getDate() - 1)
          startDate.toDateString()
          yesterday.toDateString()
          console.log(yesterday)
          startDate = yesterday;
          setDateValue("Yesterday");
          break; //'yesterday'; 
      case 3: startDate = new Date();

          let weekthis = new Date(startDate)
          weekthis.setDate(weekthis.getDate() - 7)
          startDate.toDateString()
          weekthis.toDateString()
          console.log(weekthis)
          setDateValue("This week");
          startDate = weekthis;
          break; //'this week'
      case 4: startDate = new Date();
          let monththis = new Date(startDate)
          monththis.setDate(monththis.getDate() - 31)
          startDate.toDateString()
          monththis.toDateString()
          console.log(monththis)
          setDateValue("This month");
          startDate = monththis;
          //   setDateValue(monththis);
          break; //'this month'
      // case 5: startDate = new Date(sampleDateValue);
      //     let specificdate = new Date(sampleDateValue);
      //     console.log(specificdate);
      //     break;
      default: alert('nothing'); break;
    }
    await getordersbypayment(startDate, 'start');
    setselectedDateToSend(startDate);
    setPopoverActive(false);
  }

  const htmlHeader = (
  <label>
    <Stack>
      <Stack.Item>Payment Gateway Orders</Stack.Item> 
      <Stack.Item><p>Date Range</p></Stack.Item>
      {/* <Stack.Item><Button>Today</Button></Stack.Item> */}
      <Stack.Item>
      <Popover
        active={popoverActive}
        activator={activator}
        autofocusTarget="first-node"
        onClose={togglePopoverActive}
        >
        <Card>
          <Card.Section>
          
            <Stack>
              <Stack.Item>
                <Stack vertical>
                  <Stack.Item><p onClick={() => handleDateRangeButtons(1)} className='date_range_buttons'>Today</p></Stack.Item>
                  <Stack.Item><p onClick={() => handleDateRangeButtons(2)} className='date_range_buttons'>Yesterday</p></Stack.Item>
                  <Stack.Item><p onClick={() => handleDateRangeButtons(3)} className='date_range_buttons'>This week</p></Stack.Item>
                  <Stack.Item><p onClick={() => handleDateRangeButtons(4)} className='date_range_buttons'>This month</p></Stack.Item>
                </Stack>
              </Stack.Item>
              <Stack.Item>
                
                  <DatePicker
                  month={month}
                  year={year}
                  onChange={handleDateChange}
                  onMonthChange={handleMonthChange}
                  selected={selectedDates}
                  />
                
              </Stack.Item>
            </Stack>
          </Card.Section>
        </Card>
      </Popover>
    </Stack.Item>
    {holdPage ? <Stack.Item><Spinner ></Spinner></Stack.Item>: null}
    </Stack>
  </label>)
  return (
    <div className={holdPage ? "list-page-overlay" : "list-page-overlay-visible"}>
      
    <Page title={htmlHeader} fullWidth>
      {orders.length > 0 ?
      null
      :
        <Banner title="No orders found for the selected date range." status=""></Banner>
      }
      <PageActions />
      
        <Stack>
          {orders.map((item,i)  => (
            <Stack.Item>
              <div className='gateways__section'>
              <Card>
                <Card.Section>
                  <Heading>{item[0] == '' ? "No Gateway" : item[0]}</Heading>
                </Card.Section>
                <Card.Section>
                <Stack vertical>
                  { item[1].total ? <Stack.Item>
                    <div className='payment_gateway_titles'></div>
                    <Heading>
                    Total: <CurrencyFormat  value={item[1].Total.toFixed(2)} displayType={'text'} thousandSeparator={true} thousandSpacing={'2s'} prefix={currencySymbol} />
                    {/* {currencySymbol + " " + item[1].Total.toFixed(2)} */}
                    </Heading></Stack.Item> : null}
                  <Stack.Item>{item[1].OrderCount + " Orders"}</Stack.Item>
                </Stack>
                </Card.Section>  
              </Card>
              </div>
            </Stack.Item>
          )
          )}
          
        </Stack>
      
    </Page>
    </div>
  );
}
export default Paymentgetway