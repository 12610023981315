import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import translations from "@shopify/polaris/locales/en.json";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { AppProvider } from "@shopify/polaris";
// import "@shopify/polaris/styles.css";
import '@shopify/polaris/build/esm/styles.css';
import { Provider, ResourcePicker } from "@shopify/app-bridge-react";

import {Buffer} from 'buffer';
//#region  Old Redirect 
// const shopHost = window._globalShopUrl  + '/admin';
// const config = { 
//   apiKey: window._globalApiKey, 
//   shopOrigin: window._globalShopUrl + '/admin',
//   forceRedirect: true,
//   host: Buffer.from(shopHost).toString('base64') };

//#endregion


// ------------------------------------------------------>

//#region  New Redirect 
const shopHost = window._globalShopUrl  + '/admin';

// window.location.ancestorOrigins[0]
let shopifyObjValue = window.location.ancestorOrigins != undefined ?  Object.values(window.location.ancestorOrigins) : undefined;

let shopifyURL = shopifyObjValue == undefined || shopifyObjValue.length == 0 ?  window.location.host : shopifyObjValue[0];

let _shopName = '';
 if(shopifyURL.includes("myshopify.com")){
  if(window._globalShopUrl != null){
      _shopName = shopHost;
  }
  
}else{
  if(window._globalShopUrl != null){
    _shopName = `admin.shopify.com/store/${window._globalShopUrl.substring(0, window._globalShopUrl.indexOf('.'))}`
  }

}

export const config = { 
  apiKey: window._globalApiKey,
  host: Buffer.from(_shopName).toString('base64'),
  forceRedirect: true,
 };


//#endregion

//live server (With App Bridge)
ReactDOM.render(
  <BrowserRouter>
    <Provider config={config}>
      <AppProvider i18n={translations}>
        <App />
        {/* <ResourcePicker resourceType="Product" open /> */}
      </AppProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

//localhost (Without App Bridge)
// ReactDOM.render(
//   <BrowserRouter>
//     <AppProvider i18n={translations}>
//       <App />
//     </AppProvider>
//   </BrowserRouter>,
//   document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
