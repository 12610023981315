import React, { Component,useEffect, useState, useCallback  } from 'react';  
import { Route,Link,NavLink, useHistory } from 'react-router-dom';  
// import useNavigate from 'react-use-navigate';
import createApp from '@shopify/app-bridge';
import { Navigation,Button, Frame, Page, Layout, Stack } from "@shopify/polaris";
import '../../App.css';
import '../css/custom.css';

const DashboardLayout = ({children, ...rest},props) => {  
  const history = useHistory();
  const toggleMobileMenu = () => {

    var nav = document.getElementsByClassName('Polaris-Navigation')[0];

    if (nav.classList.contains('open-menu')) {
      nav.classList.remove('open-menu');
    } else {
      nav.classList.add('open-menu');
    }


  }

  const closeMobileMenu = () => {
    var nav = document.getElementsByClassName('Polaris-Navigation')[0];
    nav.classList.remove('open-menu');
  }

const LogoutUser=()=>{
  localStorage.removeItem("od_ulbdckupfg")
  // alert("Logged out successfully");
  window.location="/login"
}

const [navigationNumber, setnavigationNumber] = useState(1);
const handleNavigation = (value) => {
  setnavigationNumber(value);
  if(value == 1){
    history.push('/upload');
  }
  else if(value == 2){
    history.push('/paymentgateway');
  }
  else if(value == 3){
    history.push('/Exportpayment');
  }
}
  
  return (  
    <Frame>
      <div className='container__navigation'>
      <Navigation location="/">
        <div className='container__navigation__inner'>
          <Navigation.Section
            items={[
              {
                label: 'COD Reconciliation',
                selected: navigationNumber == 1 ? true : false,
                onClick: () => handleNavigation(1)
              },
              {
                label: 'Payment Gateway',
                selected: navigationNumber == 2 ? true : false,
                onClick: () => handleNavigation(2)
              },
              {
                label: 'Export Payment Details',
                selected: navigationNumber == 3 ? true : false,
                onClick: () => handleNavigation(3)
              },
            ]}
            ></Navigation.Section>
          {/* <div className="nav-plan Polaris-Card__Section--subdued layout__navbar"> 
          <Button onClick={LogoutUser} destructive>Logout</Button>
          </div> */}
        </div>
      </Navigation>
      </div>
            
      <div className='container__body'>
        {children}
      </div>
    
    </Frame>
  )  
}  
  
const DashboardLayoutRoute = ({component: Component, ...rest}) => {  
  return (  
    <Route {...rest} render={matchProps => (  
      <DashboardLayout>  
          <Component {...matchProps} />  
      </DashboardLayout>  
    )} />  
  )  
};  
  
export default DashboardLayoutRoute;  