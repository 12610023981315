import React, { useEffect, useState, useCallback } from "react";
import "./css/productSearchHTML.css";
import {
  ResourceList,
  SettingToggle,
  Page,
  Avatar,
  ResourceItem,
  FooterHelp,
  Loading,
  Select,
  Heading,
  Toast,
  Frame,
  Stack,
  Button,
  ProgressBar,
  DisplayText,
  Form,
  FormLayout,
  Card,
  Thumbnail,
  Caption,
  RadioButton,
  TextStyle,
  TextField,
  Banner,
  PageActions,
  Layout,
  TextContainer,
  Modal,
  Spinner,
  List,
  DropZone,
  Link,
} from "@shopify/polaris";
import "./css/productSearchHTML.css";
import config from "../config.json";
import axios from "axios";
// import Side from "./Sidebar/SIde"
const UploadTier = (props) => {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loadingValue, setLoadingValue] = useState(0);
  const [holdPage, setHoldPage] = useState(false);
  const [progressLoading, setProgressLoading] = useState(false);
  const loadingMarkup = progressLoading && <Loading />;
  const [SampleButton, setSampleButton] = useState('Download sample file for Guest Customer Tiers');
  const [radioNormal, setradioNormal] = useState(true);
  const [radioCustomer, setradioCustomer] = useState(false);
  const [successActive, setSuccessActive] = useState(false); //success Toast
  const [successToggleMesssage, setSuccessToggleMesssage] = useState("");
  const toggleSuccessActive = useCallback(() => setSuccessActive((successActive) => !successActive), []);

  const [validationMessage, setValidationMessage] = useState(false);
  const [identifierType, setIdentifierType] = useState('');
  const [headingType, setHeadingType] = useState('');
  const [headername, setheadername] = useState('');
  const [invalidCsv, setInvalidCsv] = useState('');
  const [InvalidCsvName, setInvalidCsvName] = useState('');
  const [FaildRow, setFaildRow] = useState('');
  const [Faildstatus, setFaildstatus] = useState('');
  
  
  
  const [CSVError, setCSVError] = useState({});
  console.log("CSVError: ", CSVError);
  const [Tracking_AWB_No, setTracking_AWB_No] = useState('');
  const [Order_Name, setOrder_Name] = useState('');
  const [Customer, setCustomer] = useState('');
  const [Customer_Pincode, setCustomer_Pincode] = useState('');
  const [Logistics, setLogistics] = useState('');
  const [Price, setPrice] = useState('');
  const [Pickup_Date,setPickup_Date] = useState('');
  const [Delivery_Date, setDelivery_Date] = useState('');

  const successToast = successActive ? (
    <Toast duration={5000} content={successToggleMesssage} onDismiss={toggleSuccessActive} />
  ) : null;
  const handleRadio = useCallback((_checked, newValue) => {
    switch (newValue) {
      case "r_NormalTier": setradioNormal(true); setradioCustomer(false); setSampleButton('Download sample file for Guest Customer Tiers')
        break;
      case "r_CustomerTier": setradioNormal(false); setradioCustomer(true); setSampleButton('Download sample file for Special Customer Tiers')
        break;
      default: setradioNormal(true); setradioCustomer(false);
    }
  }, []);

  var radioButtonValue = !radioNormal ? "customer" : "normal"; //Get Discount type value

  const UploadLoopFunc = async () => {
    var checkUpload = await axios.get(config.APIURL + `/upload/isUploading?shop=${global._globalShopUrl}`);
    var UploadStatus = checkUpload.data.UploadingStatus.status;
    var UploadValue = checkUpload.data.UploadingStatus.value;
    if(UploadStatus){
      setLoadingStatus(UploadStatus);
      setLoadingValue(UploadValue);
      setTimeout(async () => {
        UploadLoopFunc();
      },1000);
    }
    else{
      setLoadingStatus(UploadStatus);
      setLoadingValue(UploadValue)
    }
   if(checkUpload.data.UploadingStatus.CSVError.ErrorStatus){
    setCSVError(checkUpload.data.UploadingStatus.CSVError)
    setValidationMessage(true)
    }

  }

  useEffect( () => {
    async function loadCheck() {
    var checkUpload = await axios.get(config.APIURL + `/upload/isUploading?shop=${global._globalShopUrl}`);
     var UploadStatus = checkUpload.data.UploadingStatus.status;
    if(UploadStatus){
        setLoadingStatus(true);
        UploadLoopFunc();
      }
      if(checkUpload.data.UploadingStatus.CSVError){
      if(checkUpload.data.UploadingStatus.CSVError.ErrorStatus){
        setCSVError(checkUpload.data.UploadingStatus.CSVError)
        setValidationMessage(true)
      }
    }
    }
    loadCheck();

    
  }, [props]);
  //DropZoneArea Start
  const [files, setFiles] = useState();
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const hasError = rejectedFiles.length > 0;

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((file) => acceptedFiles[0]),
    [],
  );

  const uploadedFiles = files && (
    <Stack>
      <Thumbnail
        size="small"
        alt='CSV'
        source="https://img.icons8.com/dotty/2x/import-csv.png"
      />
      <div>
        {files.name} <Caption>{files.size} bytes</Caption>
      </div>
    </Stack>
  );

  
  
  
const upload = async () => { //upload submit file
    setValidationMessage(false)
    if(files !== undefined && files != null){       
      const formData = new FormData();        
      formData.append('file', files); // appending file
      formData.append('shop', global._globalShopUrl); // appending body data
      setHoldPage(false);
      setProgressLoading(false);
      if(courier_dropdown == ''){
        console.log('Please select any value');
        return 'Please select any value';
      }
      let selectedCourier = courieroptions.filter((item) => item.value === courier_dropdown);
      var upload = await axios.post( `${config.APIURL}/upload/${selectedCourier[0].api}`, formData ); /* {shop: global._globalShopUrl}); //submit data */
      if(upload.data.status == "success"){
         setSuccessToggleMesssage(upload.data.message);
         setSuccessActive(true);
         setFiles();
         setLoadingStatus(true);
         setTimeout(async () => {
          UploadLoopFunc();
          },4000);// setLoadingStatus(true);
        // UploadLoopFunc();
        // setHoldPage(false);
        // setProgressLoading(false);
      }
      else{
        setCSVError(upload.data.ValidationObject);
        setValidationMessage(true);
      }
    }
    else{
      alert('Please Select File')
      setValidationMessage(false)
      setHoldPage(false);
      setProgressLoading(false);
    }
  };


  
const handleSubmit =()=>{
var data = { shop: global._globalShopUrl };
axios.post(config.APIURL + "/upload/nimbuscsv", data) //submit data
      .then((res) => {
        console.log(res);
        
        
      })
      .catch((err) =>{
        console.log(err);
      })

}
  const errorMessage = hasError && (
    <Banner
      title="The following fILE couldn’t be uploaded:"
      status="critical"
    >
      <List type="bullet">
        {rejectedFiles.map((file, index) => (
          <List.Item key={index}>
            {`"${file.name}" is not supported. File type must be .csv.`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );
  //DropZoneEnds

  //select dropdown
  const [courier_dropdown, setcourier_dropdown] = useState(config.Couriers[0].value);

  const handleSelectChange = useCallback((value) => setcourier_dropdown(value), []);

  const courieroptions = config.Couriers;
  // [
  //   {label: 'Today', value: 'today'},
  // {label: 'Yesterday', value: 'yesterday'},
  // {label: 'Last 7 days', value: 'lastWeek'}];

  return (
    <div className={holdPage ? "list-page-overlay" : "list-page-overlay-visible"}>     
    
   
      <Page title="COD Orders Reconciliation" fullWidth>
        {loadingMarkup}
        <Card>
          <Card.Section>
            {/* <DisplayText size="small">Select courier partner</DisplayText> */}
            <Stack spacing="loose" vertical>
            <Stack.Item>
              <Select
                label="Select courier partner"
                options={courieroptions}
                onChange={handleSelectChange}
                value={courier_dropdown}
              />
              </Stack.Item>
            {/* <a href={config.APIURL+'/upload/GenerateCustomerTiers?NormalTier='+radioNormal}>{SampleButton}</a> */}
            {/* <Button onClick={handleSubmit} plain>{SampleButton}</Button> */}
            <Stack.Item>
              {errorMessage}
            </Stack.Item>
            <Stack.Item> 
              <DropZone
              allowMultiple={false} 
              // label="Drp file or Browse to upload file"
                accept=".csv"
                type="file"
                errorOverlayText="File type must be .csv"
                onDrop={handleDropZoneDrop}
              >
                <DropZone.FileUpload actionHint={<label>Drop file here or <Button>Browse</Button> to upload file</label>} />
                {uploadedFiles}
              </DropZone>
              </Stack.Item> 
              {loadingStatus ? 
                <Stack.Item >

                  <div>CSV file is being uploaded, please wait until the process is complete.</div>
                  <div style={{width:'50%', float:'left', marginRight:'10px'}}>
                  <ProgressBar progress={loadingValue} />
                  </div>
                    <div>{loadingValue}%</div>
                </Stack.Item>
              : null }
              <br/>
              <Stack.Item>
              <Button primary onClick={upload} disabled={loadingStatus ? true : false}>
              {holdPage ? null : <div>Import Now</div> }
                {holdPage ?<Spinner accessibilityLabel="Spinner example" size="small" color="teal" /> :null}
                </Button>
            </Stack.Item> 
                </Stack>
       
         
          </Card.Section>
         
          {validationMessage == true ?
          <Card.Section title="last csv status">
          
          {CSVError.CsvFile_Name != null && CSVError.CsvFile_Name != undefined  && CSVError.currentTime != ""? 
              <div>
                <div style={{overflowWrap: 'break-word'}}> <span style={{color: 'red'}}>Last File Name: </span> {CSVError.CsvFile_Name}</div>
                <div style={{overflowWrap: 'break-word'}}> <span style={{color: 'red'}}>Uploaded Time: </span> {new Date(CSVError.currentTime).toDateString() + '   '+  new Date(CSVError.currentTime).toLocaleTimeString()}</div>
              </div>
             : null}

             {Object.keys(CSVError).map((keyName, i) => (
              keyName == "CsvFile_Name" || keyName == "currentTime" ||keyName == 'ErrorStatus' ? null :
              <div style={{overflowWrap: 'break-word'}}> <span style={{color: 'red'}}>{keyName}: </span>{CSVError[keyName]} </div>
            
              ))}
            
          </Card.Section>
          : null
          }

      </Card>
      </Page>
      {successToast}

    </div>
  );
};
export default UploadTier;
